import {Component,inject,signal} from '@angular/core';
import {PopupComponent} from '../../misc/components/popup/popup.component';
import {SharedModule} from '../../shared/shared.module';
import {InputEmptyToNullDirective} from '../../misc/directives/input-empty-to-null/input-empty-to-null.directive';
import {FormMessageComponent} from '../../misc/components/form-message/form-message.component';
import {AuthenticationService} from '../authentication.service';
import {FormBuilder,FormControl,Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import { environment } from '../../../environments/environment';
import {skip,take} from 'rxjs';
import {SnackMessageComponent} from '../../misc/components/snack-message/snack-message.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppService} from '../../app.service';

@Component({
	selector:'app-totp-verify',
	imports:[
		SharedModule,
		InputEmptyToNullDirective,
		FormMessageComponent
	],
	templateUrl:'./totp-verify.component.html',
	styleUrl:'./totp-verify.component.scss'
})
export class TotpVerifyComponent extends PopupComponent{
	private appService=inject(AppService);
	authenticationService=inject(AuthenticationService);
	private matSnackBar=inject(MatSnackBar);
	private formBuilder=inject(FormBuilder);
	form=this.formBuilder.group({
		token:new FormControl<string|null>(null,{validators:[Validators.required]})
	});
	serverError=signal<any>(null);
	serverResponse=signal<any>(null);
	submitButtonActive=signal<boolean>(true);
	protected readonly environment=environment;
	
	constructor(){
		super(
			inject(MatDialogRef),
			inject(Router)
		);
		
	}
	
	totpVerify():void{
		if(this.submitButtonActive()){
			const {token}=this.form.getRawValue();
			const isNotValid=!token;
			if(!this.form.valid || isNotValid){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive.set(false);
				this.serverError.set(null);
				this.serverResponse.set(null);
				this.authenticationService.totpVerify({token})
				.subscribe({
					next:async()=>{
						this.submitButtonActive.set(true);
						this.authenticationService.userObservable
						.pipe(
							skip(1),
							take(1)
						)
						.subscribe((user)=>{
							this.matSnackBar.openFromComponent(SnackMessageComponent,{
								data:{
									response:`Bienvenido/a ${user?.firstName || ''}`
								},
								duration:2000
							});
							this.appService.getCart({}).subscribe();
						});
						this.authenticationService.reloadUser();
						if(this.router.url==='/login'){
							this.close(false,'/profile');
						}else{
							this.close();
						}
					},
					error:(error)=>{
						this.submitButtonActive.set(true);
						this.serverError.set(error);
					}
				});
			}
		}
	}
	
}
