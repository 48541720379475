<div mat-dialog-title class="app-dialog-title">
	Verificar Autenticación de dos factores
	<button mat-icon-button class="close-button" >
		<mat-icon>lock</mat-icon>
	</button>
</div>
<mat-dialog-content class="dialog-content">
	<p class="app-dialog-content-title">
		Introduce el código de la aplicación de autenticación:
	</p>
	<form [formGroup]="form" (ngSubmit)="totpVerify()">
		<div class="form-content">
			<mat-form-field floatLabel="auto">
				<mat-label>Código</mat-label>
				<input matInput type="tel" name="token" formControlName="token" appInputEmptyToNull required>
			</mat-form-field>
			<app-form-message [serverError]="serverError()" [serverResponse]="serverResponse()"></app-form-message>
			<button type="submit" mat-raised-button [disabled]="!submitButtonActive()" class="submit-button app-primary-background">Enviar</button>
		</div>
	</form>
	<mat-divider></mat-divider>
	<span>No puedes recibir código de autenticación? <a [routerLink]="['/contact']" [replaceUrl]="true" (click)="close(true)">Contacta a soporte</a></span>
	<button [routerLink]="['/']" [replaceUrl]="true" (click)="authenticationService.logout()" mat-fab extended [disabled]="!submitButtonActive()" class="submit-button app-surface-container app-elevation0 login-phone-button">
		<mat-icon>logout</mat-icon>
		Log out
	</button>
</mat-dialog-content>
