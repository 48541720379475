import {computed,inject,Injectable,signal} from '@angular/core';
import {HttpClient,HttpErrorResponse} from '@angular/common/http';
import {LoginRequestDto} from './dto/login-request.dto';
import {catchError,firstValueFrom,Observable,of,tap,throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {UserResponseDto} from './dto/user-response.dto';
import {DOCUMENT} from '@angular/common';
import {SignUpRequestDto} from './dto/sign-up-request.dto';
import {PasswordResetTokenDto} from './dto/password-reset-token.dto';
import {rxResource,toObservable} from '@angular/core/rxjs-interop';
import {ResetPasswordRequestDto} from './dto/reset-password-request.dto';
import {CheckNumberResponseDto} from '../common/dto/check-number-response.dto';
import {CheckStringResponseDto} from '../common/dto/check-string-response.dto';
import {LoginPhoneRequestDto} from './dto/login-phone-request.dto';
import {Auth,signOut} from '@angular/fire/auth';
import {CheckResponseDto} from '../common/dto/check-response.dto';
import {TotpUrlResponseDto} from './dto/totp-url-response.dto';
import {TotpActivateRequestDto} from './dto/totp-activate-request.dto';
import {MatDialog} from '@angular/material/dialog';
import {TotpVerifyComponent} from './totp-verify/totp-verify.component';
import {SnackMessageComponent} from '../misc/components/snack-message/snack-message.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TotpEnableRequestDto} from './dto/totp-enable-request.dto';

@Injectable({
	providedIn:'root'
})
export class AuthenticationService{
	private readonly document=inject(DOCUMENT);
	private httpClient=inject(HttpClient);
	private cookieService=inject(CookieService);
	readonly auth=inject(Auth);
	private matDialog=inject(MatDialog);
	private matSnackBar=inject(MatSnackBar);
	totpPopup=signal<boolean>(false);
	private userResource=rxResource<UserResponseDto|null|undefined,unknown>({
		loader:()=>this.getUser().pipe(
			catchError(async(error)=>{
				switch(error.status){
					case(401):{
						return null;
					}
					case(403):{
						try{
							const totpExists=(await firstValueFrom(this.totpExists())).result;
							if(totpExists){
								const totpEnabled=(await firstValueFrom(this.totpCheck())).result;
								if(totpEnabled && this.totpPopup()){
									this.totpPopup.set(false);
									this.matDialog.open(TotpVerifyComponent,{disableClose:true});
								}
							}
						}catch(error){
							this.matSnackBar.openFromComponent(SnackMessageComponent,{data:{error}});
						}
						return null;
					}
					default:{
						return null;
					}
				}
			})
		)
	});
	user=computed<UserResponseDto|null|undefined>(
		()=>this.userResource.value()
	);
	userObservable=toObservable(this.user);
	
	constructor(){
	
	}
	
	reloadUser():void{
		this.userResource.reload();
	}
	
	getUser():Observable<UserResponseDto>{
		return this.httpClient.get<UserResponseDto>(`${environment.apiServer.url}/api/authentication/user`,{withCredentials:true});
	}
	
	logout():void{
		this.httpClient.post(`${environment.apiServer.url}/api/authentication/logout`,{},{withCredentials:true})
		.pipe(
			tap(()=>{
				// signOut(this.auth)
				// .then(console.log)
				// .catch(console.error);
				this.document.location.reload();
			}),
			catchError((error:HttpErrorResponse)=>{
				setTimeout(()=>this.document.location.reload(),200);
				return throwError(()=>error);
			})
		)
		.subscribe({next:console.log,error:console.error});
	}
	
	login(data:LoginRequestDto):Observable<UserResponseDto|null|undefined>{
		return this.httpClient.post<UserResponseDto>(`${environment.apiServer.url}/api/authentication/login`,data,{withCredentials:true});
	}
	
	signUp(data:SignUpRequestDto):Observable<UserResponseDto|null|undefined>{
		return this.httpClient.post<UserResponseDto>(`${environment.apiServer.url}/api/authentication/signup`,data,{withCredentials:true});
	}
	
	passwordResetToken(data:PasswordResetTokenDto):Observable<CheckStringResponseDto>{
		return this.httpClient.post<CheckStringResponseDto>(`${environment.apiServer.url}/api/authentication/password-reset-token`,data,{withCredentials:true});
	}
	
	resetPassword(data:ResetPasswordRequestDto):Observable<CheckNumberResponseDto>{
		return this.httpClient.post<CheckNumberResponseDto>(`${environment.apiServer.url}/api/authentication/reset-password`,data,{withCredentials:true});
	}
	
	loginPhone(data:LoginPhoneRequestDto):Observable<UserResponseDto|null|undefined>{
		return this.httpClient.post<UserResponseDto>(`${environment.apiServer.url}/api/authentication/login-phone`,data,{withCredentials:true});
	}
	
	totpExists():Observable<CheckResponseDto>{
		return this.httpClient.get<CheckResponseDto>(`${environment.apiServer.url}/api/authentication/totp-exists`,{withCredentials:true});
	}
	
	totpCheck():Observable<CheckResponseDto>{
		return this.httpClient.get<CheckResponseDto>(`${environment.apiServer.url}/api/authentication/totp-check`,{withCredentials:true});
	}
	
	createTotpSecret():Observable<TotpUrlResponseDto>{
		return this.httpClient.post<TotpUrlResponseDto>(`${environment.apiServer.url}/api/authentication/totp-secret`,{},{withCredentials:true});
	}
	
	totpActivate(data:TotpActivateRequestDto):Observable<CheckResponseDto>{
		return this.httpClient.post<CheckResponseDto>(`${environment.apiServer.url}/api/authentication/totp-activate`,data,{withCredentials:true});
	}
	
	totpEnable(data:TotpEnableRequestDto):Observable<CheckResponseDto>{
		return this.httpClient.post<CheckResponseDto>(`${environment.apiServer.url}/api/authentication/totp-enable`,data,{withCredentials:true});
	}
	
	totpDisable(data:TotpEnableRequestDto):Observable<CheckResponseDto>{
		return this.httpClient.post<CheckResponseDto>(`${environment.apiServer.url}/api/authentication/totp-disable`,data,{withCredentials:true});
	}
	
	totpVerify(data:TotpEnableRequestDto):Observable<CheckResponseDto>{
		return this.httpClient.post<CheckResponseDto>(`${environment.apiServer.url}/api/authentication/totp-verify`,data,{withCredentials:true});
	}
	
}
